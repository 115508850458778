<template>
    <div class="today-meeting-post-make" @scroll="onScroll">
        <StackRouterHeaderBar
            :class="{ 'show-title': showTitle }"
            :title="$translate('MAKE')"
            :showTitle="showTitle"
            :from="`TodayMeetingPostMakePage`"
        />
        <h2 class="title m-b-8" v-html="$translate('MAKE')" />
        <section class="section brief-plan">
            <span v-for="item in categoryState" :key="item.id" class="category-state" @click="showModalSelect(item.id)">
                <p class="f-regular f-16 c-text-lighter" v-html="'게시글 주제'"></p>
                <p class="f-medium c-primary f-16" v-html="item.answer || $translate('PLEASE_SELECT')" />
            </span>
            <!-- <span class="when flex m-t-28">
                <p class="section-title m-b-4" v-html="$translate('WHEN_TO_MEET')" />
                <p class="section-desc m-b-16" v-html="$translate('WHEN_TO_MEET_OPTIONS')" />
                <div class="date grid-col">
                    <div
                        :class="{ selected: date.selected }"
                        v-for="date in dates"
                        :key="date.expireAt"
                        @click="onSelectDate(date)"
                    >
                        {{ date.title }}
                        <p class="f-13">({{ date.desc }})</p>
                    </div>
                </div>
            </span> -->
        </section>
        <section class="section explain-plan">
            <div class="explain-title m-b-32">
                <p class="section-title m-b-12" v-html="$translate('TITLE')" />
                <TextareaWithX
                    class="input-title"
                    v-model="title"
                    :placeholder="$translate('FILL_IN_TITLE')"
                    @input="onInput"
                    @blur="onBlur"
                />
            </div>
            <div class="explain-content m-b-34">
                <!-- <p class="section-title m-b-12" v-html="$translate('INTRO_TITLE')" />
                <TextareaWithX
                    class="input-content m-b-34"
                    v-model="selfContent"
                    :placeholder="$translate('INTRODUCE_YOURSELF')"
                    @input="onInput"
                    @blur="onBlur"
                /> -->
                <div class="flex-row flex-row">
                    <p class="section-title m-b-12" v-html="$translate('CONTENT')" />
                </div>

                <TextareaWithX
                    class="input-content"
                    v-model="content"
                    :placeholder="$translate('FILL_IN_CONTENT')"
                    @input="onInput"
                    @blur="onBlur"
                />
                <p class="post-description m-t-12 f-12">
                    <span class="c-primary f-bold">연락처, SNS ID</span> 등을 기재할 경우 계정이 정지될 수 있습니다.
                </p>

                <!-- <ul class="description flex m-t-8">
                    <p v-html="$translate(`TODAY_MEETING_POST_MAKE_DESCRIPTION_TITLE`)" />
                    <li :key="idx" v-for="idx in 5">
                        <p class="dot m-r-8" />
                        <p v-html="$translate(`TODAY_MEETING_POST_MAKE_DESCRIPTION_${idx}`)" />
                    </li>
                </ul> -->
            </div>
            <div class="explain-photo">
                <PhotoInputArea
                    :from="`todaymeeting`"
                    :isOption="true"
                    @submitPhoto="updatePhoto"
                    @clearPhoto="clearPhoto"
                />
            </div>

            <!-- <div class="m-t-40">
                <TodayMeetProfileSelect :isMale="isMale" @selectprofileStyle="submitprofileStyle" />
            </div> -->
        </section>
        <div class="today-meeting-manual">
            <div class="flex-row flex-between">
                <p class="f-16 c-black">{{ $translate('TODAY_MEETING_MANUAL') }}</p>
            </div>

            <ul class="m-t-20">
                <li class="manual" v-for="m in manual" :key="m">
                    <div class="dot" />
                    <p class="text" v-html="m" />
                </li>
            </ul>
        </div>
        <!--  v-show="showButton"      -->
        <BottomButton v-show="showButton" :label="$translate('MAKE')" :disabled="buttonDisabled" @click="submit" />
    </div>
</template>
<script>
import eventService from '@/services/event'
// import { formatDate } from '@/filters'
import PhotoInputArea from '@/components/app/PhotoInputArea'
// import TodayMeetProfileSelect from '@/routes/today-meeting/components/TodayMeetProfileSelect'
export default {
    name: 'TodayMeetingPostMakePage',
    components: {
        PhotoInputArea,
        // TodayMeetProfileSelect,
    },
    data: () => ({
        showTitle: false,
        categoryOptions: [],
        categoryState: [],
        stateOptions: [],
        eventRegions: [],
        manual: [],
        dates: [],
        title: '',
        selfContent: '',
        content: '',
        profileStyle: 'mini',
        customPhoto: {},
        expireAt: '',
        categoryId: '',
        eventRegionId: '',
        stateId: '',
        showButton: true,
        timer: null,
        isTyping: false,
    }),
    mounted() {
        this.init()
        this.initDates()
    },
    computed: {
        buttonDisabled() {
            // return !(this.categoryId && this.eventRegionId && this.title && this.content && this.expireAt)
            return !(this.title && this.content)
        },
        isMale() {
            const gender = (this.$store.getters.me || {}).gender
            return gender === 0
        },
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 52 // 52 is stackrouter header height
        },
        async init() {
            this.categoryOptions = this.$store.getters.categories.filter(c => c.category === 'party_meeting')
            // this.stateOptions = this.$store.getters.states
            // this.eventRegions = this.$store.getters.eventRegions
            this.categoryState = [
                {
                    id: 'category',
                    question: 'TODAY_MEETING_CATEGORY',
                    answer: null,
                },
                // {
                //     id: 'state',
                //     question: 'TODAY_MEETING_STATE',
                //     answer: null,
                // },
            ]
            this.customPhoto = { blob: null, url: null }
            this.manual = [
                this.$translate('TODAY_MEETING_MANUAL_DESC1'),
                this.$translate('TODAY_MEETING_MANUAL_DESC2'),
                this.$translate('TODAY_MEETING_MANUAL_DESC3'),
                this.$translate('TODAY_MEETING_MANUAL_DESC4'),
                this.$translate('TODAY_MEETING_MANUAL_DESC5'),
            ]
        },
        // initDates() {
        //     const today = new Date()
        //     const tomorrow = new Date(today) // 혹시 12시 근처에 하는 것 고려..
        //     tomorrow.setDate(tomorrow.getDate() + 1)
        //     this.dates = [
        //         {
        //             title: this.$translate('TODAY'),
        //             desc: formatDate(today, 'todayMeeting'),
        //             expireAt: formatDate(today),
        //             selected: true,
        //         },
        //         {
        //             title: this.$translate('TOMORROW'),
        //             desc: formatDate(tomorrow, 'todayMeeting'),
        //             expireAt: formatDate(tomorrow),
        //             selected: false,
        //         },
        //     ]
        //     this.expireAt = this.dates[0].expireAt
        // },
        // onSelectDate(date) {
        //     this.expireAt = date.expireAt
        //     this.dates.forEach(date => {
        //         date.selected = date.expireAt === this.expireAt
        //     })
        // },
        updatePhoto(photo) {
            this.customPhoto = photo
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        onChangeImage(event) {
            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''
            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.customPhoto.url = URL.createObjectURL(croppedFile)
                    this.customPhoto.blob = croppedFile
                })
        },
        showModalSelect(id) {
            this.$modal
                .custom({
                    component: 'ModalSelect',
                    options: {
                        title: id,
                        options: this.categoryOptions,
                        // options: id === 'category' ? this.categoryOptions : this.stateOptions,
                    },
                })
                .then(res => {
                    let answer
                    if (id === 'category') {
                        answer = res.title
                        this.categoryId = res.id
                    }
                    // if (id === 'state') {
                    //     this.eventRegionId = res.id
                    //     if ([1, 2].includes(res.id)) {
                    //         this.$modal
                    //             .custom({
                    //                 component: 'ModalSelect',
                    //                 options: {
                    //                     title: '세부 위치',
                    //                     options: this.eventRegions.filter(region => region.state_id === res.id),
                    //                 },
                    //             })
                    //             .then(res => {
                    //                 answer = `${res.state_id === 1 ? '서울' : '경기'}(${res.name})`
                    //                 this.eventRegionId = res.id
                    //                 this.stateId = res.state_id
                    //                 this.categoryState.find(item => item.id === id).answer = answer
                    //             })
                    //     } else {
                    //         answer = res.name
                    //         this.eventRegionId = this.eventRegions.find(region => region.state_id === res.id).id
                    //         this.stateId = res.id
                    //     }
                    // }
                    this.categoryState.find(item => item.id === id).answer = answer
                })
        },
        submitprofileStyle(profile) {
            if (this.profileStyle !== profile) this.profileStyle = profile
        },
        async submit() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'todayMeetingEvent',
                    option: {
                        funnel: 'click_today_meeting_create_cta',
                    },
                },
            })
            const payload = {
                category_id: this.categoryId,
                // event_region_id: this.eventRegionId,
                // state_id: this.stateId,
                profile_style: this.profileStyle,
                title: this.title,
                content: this.content,
                // self_content: this.selfContent,
                // expire_at: this.expireAt,
                event_type: 'party_meeting',
            }
            if (this.customPhoto.blob) payload.photo = this.customPhoto.blob
            // 바닐라 과금 추가
            const haveToPay = this.isMale && this.profileStyle === 'privacy'
            if (haveToPay) {
                const { vanilla: currentVanilla } = this.$store.getters.badges
                const { vanilla } = this.$store.getters.settings
                if (currentVanilla < vanilla.today_meeting_create_privacy) {
                    const idx = await this.$modal.basic({
                        body: `바닐라 ${
                            vanilla.participate_meeting - currentVanilla
                        }개가 부족합니다.<br>바닐라를 구매하거나 무료 바닐라를 얻어보세요.`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_RECOMMEND',
                                class: 'btn-brd',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        this.$stackRouter.push({ name: 'InvitationFriendPage' })
                    } else if (idx === 2) {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                } else {
                    const idx = await this.$modal.basic({
                        // 아직 vanilla settings api 수정이 안되서 하드코딩
                        body: `바닐라 50개를 사용합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        await this.requestInteresting(payload)
                        await this.$store.dispatch('loadBadges')
                    }
                }
            } else {
                await this.requestInteresting(payload)
            }
        },
        async requestInteresting(payload) {
            try {
                this.$loading(true)
                const { msg } = await eventService.create(this.preparePayload(payload))
                await this.$store.dispatch('loadTodayMeetingPosts')
                this.$toast.success(msg)
                this.$stackRouter.pop()
            } catch (e) {
                if (!payload.category_id) {
                    this.$toast.error('주제를 정해주세요.')
                } else {
                    this.$toast.error(e.data.msg)
                }
            } finally {
                this.$loading(false)
            }
        },
        preparePayload(payload) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value)
            })
            return formData
        },
        onFocus() {
            this.showButton = false
        },
        onBlur() {
            this.showButton = true
        },
        onInput() {
            this.showButton = false
            if (this.timer) {
                clearTimeout(this.timer)
                this.isTyping = false
            }
            // this.timer = setTimeout(() => {
            //     this.isTyping = true
            // }, 2000)
            this.timer = setTimeout(() => {
                this.isTyping = true
            })
        },
        onClickTip() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'http://bit.ly/3sMsaRF',
            })
        },
    },
    watch: {
        isTyping: function (newVal) {
            if (newVal === false) {
                this.showButton = false
            } else {
                this.showButton = true
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .stack-router-header-bar {
    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;
    }
}
.today-meeting-post-make {
    $section-side-padding: 16px;
    $bottom-button-height: 80px;
    padding-bottom: $bottom-button-height;
    line-height: normal;
    letter-spacing: -0.2px;
    .title {
        font-size: 24px;
        font-weight: 500;
        color: black;
        padding: 0 16px;
        @include f-medium;
    }
    .section {
        padding: 40px $section-side-padding;
        &.brief-plan,
        &.explain-plan {
            border-bottom: 1px solid $grey-02;
        }
        .category-state {
            // margin-bottom: 32px;
            @include flex-row;
            @include flex-between;
            &:nth-child(2) {
                border-bottom: 1px solid $grey-02;
                padding-bottom: 28px;
            }
        }
        ::v-deep .textarea-with-x {
            .material-icons {
                display: none;
            }
        }
        hr {
            width: 100%;
            height: 1px;
            background: $grey-02;
            border: none;
        }
        &-title {
            font-size: 14px;
            font-weight: 500;
            color: $grey-08;
            @include f-medium;
        }
        &-desc {
            font-size: 12px;
            color: $grey-07;
        }
        .date {
            column-gap: 8px;
            row-gap: 8px;
            div {
                width: auto;
                height: 40px;
                color: $grey-05;
                padding: 9px 0;
                border-radius: 10px;
                border: 1px solid $grey-03;
                @include center;
                @include flex-row;
                &.selected {
                    border: solid 1px $purple-primary;
                    color: $purple-primary;
                    @include f-medium;
                }
            }
        }
        .input-title {
            height: 48px;
            padding: 12px 16px;
            ::v-deep textarea {
                line-height: 22px;
            }
        }
        .input-content {
            height: 180px;
        }
    }
    .description {
        background-color: $grey-01;
        padding: 14px;
        font-size: 12px;
        border-radius: 10px;
        li {
            display: flex;
            color: black;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        .dot {
            margin-top: 6px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $grey-07;
            @include flex-wrap;
        }
    }
    .today-meeting-manual {
        padding: 24px 16px;
        .manual {
            display: flex;
            margin-bottom: 8px;
            .dot {
                width: 4px;
                height: 4px;
                background-color: $grey-05;
                border-radius: 2px;
                margin-top: 7px;
                margin-right: 12px;
            }
            .text {
                font-size: 12px;
                color: $grey-09;
                ::v-deep span {
                    color: $grey-07;
                }
            }
        }
    }
    .bottom-button {
        position: absolute;
    }
}
</style>
